/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';

const ContactSection = () => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="flex flex-wrap">
        <div className="w-full xl:w-6 p-3 pr-5 animate__animated animate__bounceInLeft animate__delay-1s">
          <div className="text-700 line-height-3 mb-6">Get in Touch: Contact Us for Home Adult Care Services. </div>
          <ul className="list-none p-0 m-0 mt-6 text-700">
            <li className="flex align-items-center mb-3">
              <i className="pi pi-phone mr-2" />
              <span>+1 952 393 0995</span>
            </li>
            <li className="flex align-items-center mb-3">
              <i className="pi pi-map-marker mr-2" />
              <span>2380 Wycliff St, Suite 200<br />St Paul, MN 55114</span>
            </li>
            <li className="flex align-items-center">
              <i className="pi pi-inbox mr-2" />
              <span>Info@midwayhomesadultcare.com</span>
            </li>
          </ul>
        </div>
        <div className="w-full xl:w-6 flex align-items-center justify-content-center bg-indigo-50 mt-5 xl:mt-0 animate__animated animate__bounceInRight animate__delay-1s" style={{ borderRadius: '30px' }}>
          <div className="relative w-full" style={{ paddingBottom: '56.25%', height: '0' }}>
            {/* <iframe className="absolute top-0 left-0 w-full h-full" width="560" height="315" src="https://www.youtube.com/embed/Prz3phy2bHY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> */}
            <iframe
              title="google-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2822.7066253470402!2d-93.19907002397157!3d44.96995467107005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b32cbecff761db%3A0x80e1348e8208ee4d!2s2380%20Wycliff%20St%20%23200%2C%20St%20Paul%2C%20MN%2055114%2C%20USA!5e0!3m2!1sen!2ske!4v1684708913130!5m2!1sen!2ske"
              className="absolute top-0 left-0 w-full h-full"
              width="560"
              height="315"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
