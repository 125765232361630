import React from 'react';
import ContactSection from '../components/contact/contact-page/ContactSection';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHeader from '../components/services/service-page/PageHeader';

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <PageHeader title="Contact" />
      <ContactSection />
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <Seo title="Contact" />
);
